import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import CTABannerPageSection from '@hoffman/shared/src/components/page-sections/CTABannerPageSection';

const LifestyleCTABannerBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.LifestyleCTABannerBlockQuery
  >(graphql`
    query LifestyleCTABannerBlock {
      dataJson(slug: {eq: "lifestyle"}) {
        slug
        panels(name: "lifestyleCTABanner") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );
  return (
    <CTABannerPageSection
      headline={items.headline?.text}
      copy={items.copy?.text}
      cta={items.link && items.link.text}
      link={items.link && items.link.url}
    />
  );
};

export default LifestyleCTABannerBlock;
