import React from 'react';
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import Headline from '@hoffman/shared/src/components/TextFeatures/headline';

const LifestyleInteriorHeadlineBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.LifestyleInteriorHeadlineBlockQuery
  >(graphql`
    query LifestyleInteriorHeadlineBlock {
      dataJson(slug: {eq: "lifestyle"}) {
        slug
        panels(name: "lifestyleInteriorHeadlinePanel") {
          name
          models {
            type
            fields {
              text
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );

  return (
    <StyledContainer>
      <Headline text={items.headline?.text} size="37px" />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  margin-top: ${({theme}) => theme.spaces[9]}px;
`;

export default LifestyleInteriorHeadlineBlock;
