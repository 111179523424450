import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import ExpandImg from '@hoffman/shared/src/components/ExpandImg';
import fluidImgPropsFromModelFields from './helpers/fluidImgPropsFromModelFields';

const LifestyleFourthImageBlock = () => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.LifestyleFourthImageBlockQuery
  >(graphql`
    query LifestyleFourthImageBlock {
      dataJson(slug: {eq: "lifestyle"}) {
        panels(name: "lifestyleFourthImagePanel") {
          name
          models {
            type
            fields {
              asset {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              mobileAsset {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              objectPosition
            }
          }
        }
      }
    }
  `);
  const fields = dataJson?.panels?.[0]?.models?.[0]?.fields;
  const fluid = fields?.asset?.childImageSharp?.fluid;
  const mobile = fields?.mobileAsset?.childImageSharp?.fluid;
  const objectPosition = fields?.objectPosition;

  return <>{fluid && <ExpandImg {...{fluid, mobile, objectPosition}} />}</>;
};

export default LifestyleFourthImageBlock;

const ImageContainer = styled.div`
  padding-top: 96px;
`;
