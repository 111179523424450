import React, {useContext} from 'react';
import {ThemeContext} from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby';
import Copy from '@hoffman/shared/src/components/TextFeatures/copy';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';

const LifestyleIntroContentBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.LifestyleIntroContentBlockQuery
  >(graphql`
    query LifestyleIntroContentBlock {
      dataJson(slug: {eq: "lifestyle"}) {
        slug
        panels(name: "lifestyleIntroContentPanel") {
          name
          models {
            type
            fields {
              text
              url
              newTab
            }
          }
        }
      }
    }
  `);
  const items = dataJson.panels[0].models.reduce(
    (acc: object, m: {type: string; fields: any}) => {
      acc[m.type] = m.fields;
      return acc;
    },
    {},
  );
  const theme = useContext(ThemeContext);

  return (
    <ScrollAnimation
      animateIn="fadeInLeft"
      animateOnce={true}
      duration={2}
      initiallyVisible={false}
      offset={10}
    >
      <Copy
        text={items.copy?.text}
        size={`${theme.fontSizes[5]}px`}
        blockWidth="580px"
        subHeader={true}
      />
    </ScrollAnimation>
  );
};

export default LifestyleIntroContentBlock;
