import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import getImgProps from './helpers/fluidImgPropsFromModelFields';

const LifestyleIntroImageBlock = () => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.LifestyleIntroImageBlockQuery
  >(graphql`
    query LifestyleIntroImageBlock {
      dataJson(slug: {eq: "lifestyle"}) {
        panels(name: "lifestyleIntroImagePanel") {
          name
          models {
            type
            fields {
              asset {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
              objectPosition
            }
          }
        }
      }
    }
  `);
  const fields = dataJson?.panels?.[0]?.models?.[0]?.fields;
  const imgProps = getImgProps(fields);
  return <>{imgProps && <Img {...imgProps} />}</>;
};

export default LifestyleIntroImageBlock;
