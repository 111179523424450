import React from 'react';
import {Link} from 'gatsby';

import BaseLayout from '@hoffman/shared/src/components/BaseLayout';
import SEO from '../../../../packages/docs/src/components/seo';
import NavbarBlock from '@hoffman/amaris/src/blocks/NavbarBlock';
import LifestyleInteriorHeadlineBlock from '~blocks/LifestyleInteriorHeadlineBlock';
import LifestyleIntroImageBlock from '~blocks/LifestyleIntroImageBlock';
import LifestyleIntroContentBlock from '~blocks/LifestyleIntroContentBlock';
import LifestyleSecondImageBlock from '~blocks/LifestyleSecondImageBlock';
import LifestyleSecondContentBlock from '~blocks/LifestyleSecondContentBlock';
import LifestyleThirdImageExpandBlock from '~blocks/LifestyleThirdImageExpandBlock';
import LifestyleFourthImageBlock from '~blocks/LifestyleFourthImageBlock';
import LifestyleFourthContentBlock from '~blocks/LifestyleFourthContentBlock';
import LifestyleFifthImageExpandBlock from '~blocks/LifestyleFifthImageExpandBlock';
import LifestyleSixthImageBlock from '~blocks/LifestyleSixthImageBlock';
import LifestyleSixthContentBlock from '~blocks/LifestyleSixthContentBlock';
import LifestyleSeventhImageExpandBlock from '~blocks/LifestyleSeventhImageExpandBlock';
import LifestyleCTABannerBlock from '~blocks/LifestyleCTABannerBlock';
import FooterBlock from '~blocks/FooterBlock';
import Section from '@hoffman/shared/src/components/Section';
import SectionBackgroundGroup from '@hoffman/shared/src/components/SectionBackgroundGroup';
import SectionPadding from '@hoffman/shared/src/components/SectionPadding';
import {useStaticQuery, graphql} from 'gatsby';

const SecondPage = () => {
  const {dataJson} = useStaticQuery<GatsbyTypes.LifestylePageQuery>(graphql`
    query LifestylePage {
      dataJson(slug: {eq: "lifestyle"}) {
        id
        seoDescription
        seoTitle
        bg {
          desktop {
            childImageSharp {
              fluid(maxWidth: 3000, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          mobile {
            childImageSharp {
              fluid(maxWidth: 3000, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const bgImgs = {
    desktop: dataJson?.bg[0]?.desktop?.childImageSharp?.fluid,
    mobile: dataJson?.bg[0]?.mobile?.childImageSharp?.fluid,
  };
  return (
    <BaseLayout>
      <SEO title={dataJson.seoTitle} description={dataJson.seoDescription} />
      <NavbarBlock />

      <SectionBackgroundGroup altBg={true}>
        <SectionPadding py="shorter">
          <LifestyleInteriorHeadlineBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <Section themeName="DARK">
        <SectionBackgroundGroup
          bgImg={bgImgs.desktop}
          bgImgMobile={bgImgs.mobile}
        >
          <SectionPadding px="none" py="none">
            <LifestyleIntroImageBlock />
          </SectionPadding>

          <SectionPadding px="narrowest" py="tall">
            <LifestyleIntroContentBlock />
          </SectionPadding>

          <SectionPadding px="none" py="none">
            <LifestyleSecondImageBlock />
          </SectionPadding>
        </SectionBackgroundGroup>
      </Section>

      <SectionBackgroundGroup>
        <SectionPadding py="tall">
          <LifestyleSecondContentBlock />
        </SectionPadding>

        <SectionPadding px="none" py="standard">
          <LifestyleThirdImageExpandBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <Section themeName="DARK">
        <SectionBackgroundGroup>
          <SectionPadding px="none" py="standard">
            <LifestyleFourthImageBlock />
          </SectionPadding>
          <SectionPadding py="tall">
            <LifestyleFourthContentBlock />
          </SectionPadding>

          <SectionPadding px="none" py="standard">
            <LifestyleFifthImageExpandBlock />
          </SectionPadding>
        </SectionBackgroundGroup>
      </Section>

      <SectionBackgroundGroup altBg={true}>
        <SectionPadding px="none" py="standard">
          <LifestyleSixthImageBlock />
        </SectionPadding>
        <SectionPadding>
          <LifestyleSixthContentBlock />
        </SectionPadding>

        <SectionPadding px="none" py="none">
          <LifestyleSeventhImageExpandBlock />
        </SectionPadding>
      </SectionBackgroundGroup>

      <Section themeName="DARK">
        <SectionBackgroundGroup>
          <SectionPadding>
            <LifestyleCTABannerBlock />
          </SectionPadding>
        </SectionBackgroundGroup>
      </Section>
      <FooterBlock />
    </BaseLayout>
  );
};

export default SecondPage;
