import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import ExpandImg from '@hoffman/shared/src/components/ExpandImg';

const LifestyleSecondImageBlock = (props) => {
  const {dataJson} = useStaticQuery<
    GatsbyTypes.LifestyleSecondImageBlockQuery
  >(graphql`
    query LifestyleSecondImageBlock {
      dataJson(slug: {eq: "lifestyle"}) {
        panels(name: "lifestyleSecondaryImagePanel") {
          name
          models {
            type
            fields {
              asset {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              mobileAsset {
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 95) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  `);
  const fields = dataJson?.panels?.[0]?.models?.[0]?.fields;
  const fluid = fields?.asset?.childImageSharp?.fluid;
  const mobile = fields?.mobileAsset?.childImageSharp?.fluid;

  return <>{fluid && <ExpandImg fluid={fluid} mobile={mobile} />}</>;
};

export default LifestyleSecondImageBlock;
