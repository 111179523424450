import {FluidObject} from 'gatsby-image';

// todo: grab this type from the gatsby generated type wastelands if possible
interface modelFields {
  asset?: {
    childImageSharp?: {
      fluid?: FluidObject;
    };
  };
  alt?: string;
  objectPosition?: string;
}

export default (fields?: modelFields) => {
  const fluid = fields?.asset?.childImageSharp?.fluid;
  if (!fluid) {
    return undefined;
  }

  // TODO //////////////////////////////////////////////////////////////////////
  // DOCUMENT THIS /////////////////////////////////////////////////////////////
  ////////// OMG  //////////////////////////////////////////////////////////////
  const imgStyle = fields?.objectPosition
    ? {
        objectPosition: fields.objectPosition,
      }
    : undefined;

  return {
    fluid,
    alt: fields?.alt || undefined,
    imgStyle,
    objectPosition: fields?.objectPosition || undefined,
  };
};
